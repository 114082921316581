// routes [path: string, params?: Record<string, string> ]
type Home = ['home']
type EncryptedPollVerification = ['verification']
type PollResults = ['results']

type Route = Home | EncryptedPollVerification | PollResults

export const route = (...[path]: Route): string => {
  if (path == 'home') {
    return '/'
  } else if (path == 'verification') {
    return '/verification'
  } else if (path == 'results') {
    return '/results'
  }

  return '/'
}
