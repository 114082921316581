import { getCognito, getToken } from './auth'
import {
  getPoll,
  getActiveQuestion,
  getAgent,
  getCertificates,
  registerProxyVoter,
  getPollFiles,
  getProxyAgents,
  getPollZoomCredentials,
  getRemoteMeetingLink,
  getPendingEncryptedVotesValidation,
  getProxyInstructionQuestions,
  getDeferredQuestions,
  getEarlyQuestions,
  getResultsQuestions,
  getResultsStats,
  getPollStats
} from './polls'
import { emitVote, validateProxyInstructionVote } from './votes'
import { getCensus, createVoter, getFileUrl } from './census'
import { getDomainTheme } from './theme'
import { getVerificationFileUrl, validateVote, uploadVerificationFile, deleteVerificationFile } from './verification'

export default {
  getCognito,
  getPoll,
  getActiveQuestion,
  getAgent,
  getCertificates,
  emitVote,
  getCensus,
  createVoter,
  getFileUrl,
  registerProxyVoter,
  getPollFiles,
  getProxyAgents,
  getDomainTheme,
  getToken,
  getPollZoomCredentials,
  getVerificationFileUrl,
  uploadVerificationFile,
  deleteVerificationFile,
  validateVote,
  getRemoteMeetingLink,
  getPendingEncryptedVotesValidation,
  validateProxyInstructionVote,
  getProxyInstructionQuestions,
  getDeferredQuestions,
  getEarlyQuestions,
  getResultsQuestions,
  getResultsStats,
  getPollStats
}
